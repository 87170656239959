import { Injectable } from '@angular/core';
import { EmbedGuideModel } from '../../domain/guides/embed-guide.model';

@Injectable()
export class EmbedGuideCartService {
  private embedGuides: EmbedGuideModel[] = [];
  private origin: string;
  private embedId: number;

  setEmbedGuides(embedGuides: EmbedGuideModel[]) {
    this.embedGuides = embedGuides;
  }

  getEmbedGuides() {
    return this.embedGuides;
  }

  removeEmbedGuide(id: number) {
    this.embedGuides.splice(this.embedGuides.findIndex(guide => guide.id === id), 1);
  }

  setOrigin(origin: string) {
    this.origin = origin;
  }

  getOrigin() {
    return this.origin;
  }

  setEmbedId(embedId: string){
    this.embedId = +embedId;
  }

  getEmbedId(){
    return this.embedId;
  }
}
