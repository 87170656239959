<div class="sidenav-header" (click)="screenWidth < 768 ? sidenav.close() : ''">
  <div class="ml-2 logo-wrap w-100">
    <img src="/assets/img/logo.png" alt="tgf logo" height="20px" />
  </div>
  <div class="sidenav-header-button mr-2">
    <button
      mat-icon-button
      color="primary"
      matTooltip="Open/close menu"
      (click)="accordion.openAll(); closeSidebar();">
      <mat-icon aria-hidden="false" aria-label="Open/close menu">menu</mat-icon>
    </button>
  </div>
</div>
<div class="sidenav-menu" (click)="screenWidth < 768 ? sidenav.close() : ''">

  <mat-accordion class="app-nav-accordion" #accordion="matAccordion" [multi]="true">
    <div *ngFor="let item of sideMenu">
      <mat-expansion-panel class="mat-elevation-z0" [expanded]="item.expanded" [routerLinkActive]="['active-title']" [hideToggle]="item.hideToggle" [disabled]="item.disabled">
        <mat-expansion-panel-header class="border-0">
          <mat-panel-title>
            <div class="panel-title row m-0" [routerLink]="item?.routerLink">
              <mat-icon class="grey-icon">{{item.icon_name}}</mat-icon>
              {{ item.panel_header }}
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul>
          <div *ngFor="let subItem of item.subMenu">
            <li [routerLinkActive]="['active']">
              <a [routerLink]="subItem.routerLink">
                <span>{{ subItem.name }}</span>
              </a>
            </li>
          </div>
        </ul>
      </mat-expansion-panel>
    </div>
  </mat-accordion>
</div>
