import { Injectable } from '@angular/core';
import { NavigationEnd, Params, Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class RouterExtService {
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  constructor(private router: Router, private location: Location) {
    // router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     this.previousUrl = this.currentUrl;
    //     this.currentUrl = event.url;
    //   }
    // });
  }

  getPreviousUrl() {
    return this.previousUrl;
  }

  navigateToPreviousUrlOr(alternativeRoute?: string) {
    if (!this.previousUrl || this.isPreviousRoot()) {
      this.navigateByUrl(alternativeRoute || '/');
    } else {
      this.navigateByUrl(this.previousUrl);
    }
  }

  navigateByUrl(param: string) {
    this.router.navigateByUrl(param);
  }

  isPreviousRoot() {
    return this.previousUrl === '/';
  }

  mergeQueryParams(params: Params) {
    const urlTree = this.router.createUrlTree([], {
      queryParams: { ...params },
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });
    this.location.replaceState(urlTree.toString());
  }

  setPreviousUrl(route: string) {
    this.previousUrl = route;
  }
}
