import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
    <div>
      <router-outlet></router-outlet>
      <app-loader #loader></app-loader>
    </div>`
})
export class AppComponent {
}
