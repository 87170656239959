import {Component} from '@angular/core';
@Component({
  selector: 'app-loader',
  template: `
    <div class="app-global-spinner-background">
      <div class="app-global-spinner">
        <mat-spinner [color]="'primary'" [mode]="'indeterminate'"></mat-spinner>
      </div>
    </div>`,
  styleUrls: ['./global-spinner.component.scss']
})
export class GlobalSpinnerComponent {

}
