import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { TokenModel } from '../../domain/utils/token.model';
import { Observable, switchMap, tap } from 'rxjs';
import { UserModel } from '../../domain/user/user.model';

@Injectable({ providedIn: 'root' })
export class AuthService {
  public user: UserModel;

  constructor(private httpClient: HttpClient) {
  }

  login(email: string, password: string) {
    return this.httpClient.post<TokenModel>(`${environment.serviceUrl}/login`, { email, password }).pipe(
      tap((response) => this.setToken(response.token)),
      switchMap(() =>
        this.getCurrentUser().pipe(
          tap((currentUser) => {

          })
        )
      )
    );
  }

  logout(force = false) {
    if (force) {
      localStorage.removeItem('access_token');
    }
    return this.httpClient.post(`${environment.serviceUrl}/logout`, {}).pipe(tap(response => {
      localStorage.removeItem('access_token');
    }));
  }

  getCurrentUser(): Observable<UserModel> {
    return this.httpClient.get<UserModel>(`${environment.serviceUrl}/me`);
  }

  getToken() {
    return localStorage.getItem('access_token');
  }

  setToken(token: string) {
    localStorage.setItem('access_token', token);
  }

  setUser(user: UserModel) {
    this.user = user;
  }

  getUser(): UserModel {
    return this.user;
  }
}
