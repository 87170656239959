export class RegiaModel {
  id: number;
  name: string;
  short_code: string;
  slug: string;
  continent_slug: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
}

export enum RegiaEnum {
  USA = 'USA',
  CANADA = 'CANADA',
  CARIBBEAN = 'CARIBBEAN'
}

export const RegiaLabel = {
  USA: 'USA',
  CANADA: 'Canada',
  CARIBBEAN: 'Caribbean'
}
