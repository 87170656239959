import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ServerErrorUtils } from '../../shared/utils/server-error-utils';
import { Injectable, Injector } from '@angular/core';
import { SnackBarService } from '../services/snackbar.service';
import { AuthService } from '../../shared/services/api/auth.service';
import { Router } from '@angular/router';
import { GlobalSpinnerService } from '../services/global-spinner.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private injector: Injector,
              private router: Router,
              private gss: GlobalSpinnerService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const sbs = this.injector.get(SnackBarService);
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error?.error?.code === 401 && this.router.url != '/login') {
          const authService = this.injector.get(AuthService);
          if (authService.getToken()) {
            authService.logout(true).subscribe(response => {
              this.router.navigate(['login']);
            });
          } else {
            this.router.navigate(['login']);
          }
        }

        if(this.router.url =='/embed/order'){
          this.gss.hideLoader();
          return throwError(error);
        };

        if(this.router.url =='/reports/new-order'){
          this.gss.hideLoader();
          return throwError(error);
        };

        ServerErrorUtils.serverValidationOrMsg(error, sbs, 'Something went wrong. Please try again.');
        this.gss.hideLoader();
        return throwError(error);
      })
    );
  }
}
