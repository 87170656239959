import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarService } from '../../core/services/snackbar.service';

export class ServerErrorUtils {

  static getValidationMessages(response: HttpErrorResponse): string {
    if (response && response.error && response.error.message) {
      return response.error.message;
    }
    return null;
  }

  static serverValidationOrMsg(error: HttpErrorResponse, sbs: SnackBarService, defaultMsg: string) {
    const messages = ServerErrorUtils.getValidationMessages(error);
    sbs.error(messages ? messages : defaultMsg);
  }

}
