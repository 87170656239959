import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { APP_ROUTES, appRoutes } from './app.route';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './core/modules/material.module';
import { EmbedGuideCartService } from './shared/services/utils/embed-guide-cart.service';
import { GlobalSpinnerComponent } from './core/components/global-spinner/global-spinner.component';
import { GlobalSpinnerService } from './core/services/global-spinner.service';
import { MainComponent } from './main.component';
import { SideMenuComponent } from './core/components/side-menu/side-menu.component';
import { LoginComponent } from './core/components/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SnackBarService } from './core/services/snackbar.service';
import { HeaderInterceptor } from './core/interceptors/header.interceptor';
import { HttpErrorInterceptor } from './core/interceptors/error.interceptor';
import { SortablejsModule } from 'ngx-sortablejs';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";

@NgModule({
  declarations: [
    AppComponent,
    GlobalSpinnerComponent,
    MainComponent,
    SideMenuComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      // enableTracing: true  // <-- debugging purposes only
    }),
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    SortablejsModule.forRoot({ animation: 150 }),
    GooglePlaceModule,
  ],
  providers: [
    EmbedGuideCartService,
    GlobalSpinnerService,
    SnackBarService,
    {provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
    {
      provide: APP_ROUTES,
      useValue: appRoutes
    }
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
