import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RouterExtService } from '../../services/router-ext.service';
import { GlobalSpinnerService } from '../../services/global-spinner.service';
import { ServerErrorUtils } from '../../../shared/utils/server-error-utils';
import { SnackBarService } from '../../services/snackbar.service';
import { AuthService } from '../../../shared/services/api/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  userForm: FormGroup;
  serverErrorUtils = ServerErrorUtils;
  fieldType: 'password' | 'text' = 'password';

  constructor(
    private formBuilder: FormBuilder,
    private snackBarService: SnackBarService,
    private gss: GlobalSpinnerService,
    private routerService: RouterExtService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.setupUserForm();
  }

  login() {
    if (this.userForm.dirty && this.userForm.valid) {
      this.gss.showLoader();
      this.authService.login(this.userForm.value.email, this.userForm.value.password).subscribe(
        (response) => {
          this.gss.hideLoader();
          this.routerService.navigateByUrl('');
        }
      );
    }
  }

  private setupUserForm() {
    this.userForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

}
