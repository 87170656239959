import { AfterViewChecked, ChangeDetectorRef, Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-main-component',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements AfterViewChecked {
  screenWidth: number;
  mobileTopBar = document.getElementsByClassName('mobile-top-bar');
  scrollOffset: number;

  constructor(private cdRef: ChangeDetectorRef) {
    this.screenWidth = window.innerWidth;
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  @HostListener('window:resize')
  resizeScreen() {
    this.screenWidth = window.innerWidth;
  }

  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    if ($event.path && $event.path.length) {
      this.scrollOffset = $event.path[1].scrollY;
    } else {
      this.scrollOffset = 0;
    }
  }
}
