import { Route } from '@angular/compiler/src/core';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';
import { APP_ROUTES } from 'src/app/app.route';
import { SideMenuService } from '../../../shared/services/utils/side-menu.service';
import { sideMenu } from '../../../shared/configs/side-menu';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit, OnDestroy {
  @Input() screenWidth: number;
  @Input() sidenav: MatSidenav;

  sidebarSubscription: Subscription;
  sideMenu = sideMenu;

  constructor(
    @Inject(APP_ROUTES)
    private appRoutes: Route[],
    private sideMenuService: SideMenuService,) {
  }

  ngOnInit(): void {
    this.subscribeToSidebarChanges();
  }

  ngOnDestroy() {
    this.sidebarSubscription.unsubscribe();
  }

  closeSidebar() {
    this.sideMenuService.closeSidebar();
  }

  private subscribeToSidebarChanges() {
    this.sidebarSubscription = this.sideMenuService.sidebarOpenedSubject.subscribe(() => {
      this.sidenav.toggle();
    });
  }
}
