import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { AuthService } from '../../shared/services/api/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PreventLoginGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): boolean | UrlTree {
    if (this.authService.getToken()) {
      return this.router.createUrlTree(['']);
    }

    return true;
  }
}
