export const environment = {
  production: false,
  serviceUrl: 'https://dev-api.travelguidesfree.com/api',
  //serviceUrl: 'http://127.0.0.1:8000/api',
  imageUrl: 'https://dev-api.travelguidesfree.com',
  embedUrl: 'https://dev-api.travelguidesfree.com/api',
  //embedUrl: 'http://127.0.0.1:8000/api',
  appSourceUrl: 'https://dev-app.travelguidesfree.com',
  //appSourceUrl: 'http://127.0.0.1:8000/',
  mapsApiKey: 'AIzaSyCfdSvcZ_w1B_X5cdqVIT83bqhl5YKMgDs',
  //websiteUrl: 'http://localhost/tgf/stg-tgf'
  websiteUrl: 'https://dev-web.travelguidesfree.com'
};
