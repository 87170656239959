<div class="auth login d-flex">
  <div class="container">
    <div class="row justify-content-center align-items-center">
      <div class="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4">

        <div class="login-header text-center mt-5 mb-3">

        </div>

        <div class="login-content mt-5">
          <mat-card class="p-5 mb-5">
            <mat-card-title class="mb-2 pb-3 text-center flex-column">
              <img src="/assets/img/logo.png" alt="tgf logo" width="230px" />
<!--              <small class="d-block mt-1 text-muted mb-3">Lorem ipsum</small>-->
<!--              <i class="fas fa-user-circle fa-5x d-block text-primary mat-form-field-outline-thick" aria-hidden="true"></i>-->
            </mat-card-title>
            <mat-card-content>
              <div>
                <form [formGroup]="userForm" (keyup.enter)="login()" class="d-flex flex-column">

                  <mat-form-field floatLabel="auto" appearance="outline" class="mb-1 p-0">
                    <mat-label>Email</mat-label>
                    <input formControlName="email" matInput id="email" autocomplete="off"/>
<!--                    <mat-error>{{serverErrorUtils.formErrorMsg(userForm.get('email'))}}</mat-error>-->
                  </mat-form-field>

                  <mat-form-field floatLabel="auto" appearance="outline">
                    <mat-label>Password</mat-label>
                    <input formControlName="password" matInput [type]=fieldType id="password" autocomplete="off"/>
<!--                    <i class="fas fa-eye hand" matSuffix (click)="fieldType = 'text'" *ngIf="fieldType === 'password'"></i>-->
<!--                    <i class="fas fa-eye-slash hand" matSuffix (click)="fieldType = 'password'" *ngIf="fieldType === 'text'"></i>-->
<!--                    <mat-error>{{serverErrorUtils.formErrorMsg(userForm.get('password'))}}</mat-error>-->
                  </mat-form-field>

                </form>
                <mat-divider [inset]="true" class="mt-2 mb-4"></mat-divider>
              </div>
            </mat-card-content>
            <mat-card-footer class="text-center">
              <button type="submit" mat-flat-button color="primary" class="pr-4 pl-4" (click)="login()">
                <span>Login</span>
              </button>
            </mat-card-footer>
          </mat-card>

        </div>

      </div>
    </div>
  </div>
</div>
