import { Route, Routes } from '@angular/router';
import { InjectionToken } from '@angular/core';
import { MainComponent } from './main.component';
import { LoginComponent } from './core/components/login/login.component';
import { PreventLoginGuard } from './core/guards/prevent-login.guard';
import { AuthGuard } from './core/guards/auth.guard';

export const APP_ROUTES = new InjectionToken<Route[]>('APP_ROUTES');

export const appRoutes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [PreventLoginGuard] },
  {
    path: 'embed',
    loadChildren: () => import('./modules/embed/embed.module').then(m => m.EmbedModule)
  },
  {
    path: 'embed-v2',
    loadChildren: () => import('./modules/embed-v2/embed-v2.module').then(m => m.EmbedModule)
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'users',
        loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'partners',
        loadChildren: () => import('./modules/partners/partners.module').then(m => m.PartnersModule)
      },
      {
        path: 'destinations',
        loadChildren: () => import('./modules/destinations/destinations.module').then(m => m.DestinationsModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'modules',
        loadChildren: () => import('./modules/modules/modules.module').then(m => m.ModulesModule)
      },
      {
        path: 'inventory',
        loadChildren: () => import('./modules/inventory/inventory.module').then(m => m.InventoryModule)
      },
      { path: '**', redirectTo: 'reports/summary' }
    ]
  },
  { path: '**', redirectTo: '' }
];
