import { RegiaEnum } from '../domain/regia/regia.model';

type SubMenuItem = {
  name: string;
  routerLink: Array<string>;
};

type SideMenuItem = {
  panel_header: string;
  icon_name: string;
  expanded: boolean;
  subMenu: Array<SubMenuItem>;
  hideToggle: boolean;
  disabled: boolean;
  routerLink?: Array<string>;
};

export const sideMenu: Array<SideMenuItem> = [
  {
    panel_header: 'Report',
    icon_name: 'grid_view',
    expanded: true,
    hideToggle: false,
    disabled: false,
    subMenu: [
      {
         name: 'Report builder',
         routerLink: ['reports', 'report-builder'],
      },
      {
        name: 'Summary report',
        routerLink: ['reports', 'summary'],
      },
      {
        name: 'Order summary',
        routerLink: ['reports', 'order-summary'],
      },
      {
        name: 'Leads report',
        routerLink: ['reports', 'leads'],
      },
      {
        name: 'Acquisition report',
        routerLink: ['reports', 'acquisition'],
      },
      {
        name: 'Pop-up orders report',
        routerLink: ['reports', 'order-popup'],
      },
      {
        name: 'Survey report',
        routerLink: ['reports', 'survey'],
      },
      {
        name: 'Partner report',
        routerLink: ['reports', 'partner'],
      },
      {
        name: 'Demo and Geo report',
        routerLink: ['reports', 'demographics'],
      },
      {
        name: 'Breakdown report',
        routerLink: ['reports', 'breakdown'],
      },
      {
        name: 'Region report',
        routerLink: ['reports', 'region'],
      },
      {
        name: 'Overview report',
        routerLink: ['reports', 'overview']
      },
      {
        name: 'Order attempts',
        routerLink: ['reports', 'order-attempts'],
      },
      {
        name: 'Embeds report',
        routerLink: ['reports', 'embeds'],
      },
      {
        name: 'Engagement report',
        routerLink: ['reports', 'engagement'],
      },
      {
        name: 'Invoice report',
        routerLink: ['reports', 'invoices'],
      }
    ]
  },
  {
    panel_header: 'Inventory',
    icon_name: 'grid_view',
    expanded: true,
    hideToggle: false,
    disabled: false,
    subMenu: [
      {
        name: 'Guides',
        routerLink: ['inventory', 'guides'],
      },
      {
        name: 'Campaigns',
        routerLink: ['inventory', 'campaigns'],
      },
      {
        name: 'Things To Do',
        routerLink: ['inventory', 'things-to-do'],
      },
      {
        name: 'Embeds',
        routerLink: ['inventory', 'embeds'],
      },
      {
        name: 'TTD Categories',
        routerLink: ['inventory', 'ttd-categories'],
      },
      {
        name: 'Promo',
        routerLink: ['inventory', 'promo'],
      },
    ],
  },
  {
    panel_header: 'Partners',
    icon_name: 'grid_view',
    expanded: true,
    hideToggle: false,
    disabled: false,
    subMenu: [
      {
        name: 'Organizations',
        routerLink: ['partners', 'organization'],
      },
      {
        name: 'Agencies',
        routerLink: ['partners', 'agency']
      },
      {
        name: 'Sales Persons',
        routerLink: ['partners', 'sales-persons']
      }
    ],
  },
  {
    panel_header: 'Destinations',
    icon_name: 'grid_view',
    expanded: false,
    hideToggle: false,
    disabled: false,
    subMenu: [
      {
        name: 'USA',
        routerLink: ['destinations', 'list', RegiaEnum.USA.toLowerCase()],
      },
      // {
      //   name: 'Canada',
      //   routerLink: ['destinations', 'list', RegiaEnum.CANADA.toLowerCase()],
      // },
      {
        name: 'Caribbean',
        routerLink: ['destinations', 'list', RegiaEnum.CARIBBEAN.toLowerCase()]
      },
    ],
  },
  {
    panel_header: 'Modules',
    icon_name: 'grid_view',
    expanded: false,
    hideToggle: false,
    disabled: false,
    subMenu: [
      {
        name: 'Blacklist',
        routerLink: ['modules', 'blacklist'],
      },
      {
        name: 'Send emails',
        routerLink: ['modules', 'emails'],
      },
    ],
  },
  {
    panel_header: 'Users',
    icon_name: 'grid_view',
    expanded: false,
    hideToggle: true,
    disabled: true,
    subMenu: [],
    routerLink: ['users']
  }
];
