<mat-sidenav-container #idOdTableNotToPrint>
  <mat-sidenav-content>
    <div
      class="mobile-top-bar d-flex align-items-center d-md-none"
      (scroll)="onScrollEvent($event)"
      [ngClass]="{ fixed: scrollOffset > 0 }">
      <div class="col p-0 d-flex align-items-end" (click)="sidenav.toggle()">
        <button mat-icon-button><i class="fas fa-bars"></i></button>
      </div>
      <div class="col col-auto text-center" (click)="sidenav.toggle()"></div>
    </div>
    <main class="main" [class.sidenav-closed]="!sidenav.opened">
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>

  <mat-sidenav
    [mode]="screenWidth > 768 ? 'side' : 'over'"
    [opened]="!screenWidth || screenWidth > 768"
    [class.sideOpened]="sidenav.opened"
    [class.sideClosed]="!sidenav.opened"
    #sidenav>
    <app-side-menu [screenWidth]="screenWidth" [sidenav]="sidenav"></app-side-menu>
  </mat-sidenav>
</mat-sidenav-container>

